import React, { PropsWithChildren } from 'react';

import { classes } from './Content.st.css';

export const Content: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={classes.root}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};
